import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Deload Week`}</em></p>
    <p>{`Plyo Pushups 12-12-12`}</p>
    <p>{`Band Straight Arm Pulldowns 12-12-12`}</p>
    <p>{`then,`}</p>
    <p>{`3 rounds of:`}</p>
    <p>{`3-Bar Muscle Ups`}</p>
    <p>{`6-HSPU’s`}</p>
    <p>{`9-GHD Situps`}</p>
    <p>{`then, 3 rounds of:`}</p>
    <p>{`9-CTB Pullups`}</p>
    <p>{`12-Legless Wall Balls (20/14 to 9′ target)`}</p>
    <p>{`15-K2E’s`}</p>
    <p>{`then, 3 rounds of:`}</p>
    <p>{`15-Pullups`}</p>
    <p>{`18-Ring Pushups`}</p>
    <p>{`21-Med Ball Situps (20/14`}{`#`}{`)`}</p>
    <p>{`for total time.`}</p>
    <p><em parentName="p">{`*`}{`compare to 11/26/16`}</em></p>
    <p><strong parentName="p">{`*`}{`Saturday, January 13th is Cardinal Fitness Day at the Yum!.  The
Cards play Virginia Tech at 4:00pm.  With the following link you can get
discounted tickets as a CrossFit the Ville member!  After the game there
will be a CrossFit wod on the court along with several other workout
sessions.  Lets get a big group together and represent The Ville at the
Yum center!  Family is invited as well.`}</strong></p>
    <p><strong parentName="p"><a parentName="strong" {...{
          "href": "https://fevo.me/fitday"
        }}>{`https://fevo.me/fitday`}</a></strong></p>
    <p><strong parentName="p">{`*`}{`Holiday Schedule`}{`*`}</strong></p>
    <p><em parentName="p">{`-Sunday, Dec 24th: Free 12:30 class!`}</em></p>
    <p><em parentName="p">{`-Monday, Dec 25th: Closed for Christmas Day`}</em></p>
    <p><em parentName="p">{`-Tuesday, Dec 26th: 12:00 & 4:30pm classes only.  Open gym between
1-4:30.  The gym will close after the 4:30 class.`}</em></p>
    <p><em parentName="p">{`-Sunday, Dec 31st: 12:30 class`}</em></p>
    <p><em parentName="p">{`-Monday, January 1st: Free New Years Day class at 10:30!  No open gym,
all other classes are cancelled.`}</em></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Get ready for the 3rd Annual Pre Open at The Ville!  Starting the
first week of January.  RX, Scaled, and Masters over 50 Divisions.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      